import { POSTClaimSocialFollower } from 'api/v1/social-follower';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import numberUtils from 'shared/utils/number';
import { changeBalance, changeReplay } from 'store/reducers/profile';
import { SocialModalContainer } from '../css';
import toast from 'react-hot-toast';
import { ISocialResponse } from 'api/v1/social';

const SocialModal: FC<{
	modal: ISocialResponse['modal'];
	status: boolean;
	title: string;
	reward: number;
	link: string;
	id: number;
	isClaimed: boolean;
	setStatus: (status: boolean) => void;
}> = ({ modal, status, title, reward, link, id, setStatus, isClaimed }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [canCheck, setCanCheck] = useState<boolean>(false);
	// revert this to do
	const dispatch = useDispatch();
	const onCheckClickHandler = () => {
		setIsLoading(true);
		POSTClaimSocialFollower(id)
			.then(res => {
				window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
				dispatch(changeBalance(res));
				dispatch(changeReplay());
				toast.success('reward has been claimed successfully');
				onClose();
			})
			.catch(() => {
				setCanCheck(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Modal status={_status} onClose={onClose} title={''} isBottomSheet>
			<SocialModalContainer>
				<img className='header_pic' src={modal.modalIconUrl} alt='' />
				<h3>{title}</h3>
				<p>{modal.modalText}</p>
				<Button
					variant='primary'
					href={link}
					target='_blank'
					onClick={() => {
						if (!isClaimed) {
							setCanCheck(true);
						}
					}}
				>
					{modal.buttonTitle}
				</Button>

				<div className='reward_container'>
					<img src='/img/dollar.webp' alt='' className='dollar_img' draggable={false} />
					<div className='value'>+{numberUtils.formatPriceWithDecimals(reward)}</div>
				</div>
				<Button
					className='btn'
					variant='primary'
					onClick={onCheckClickHandler}
					isLoading={isLoading}
					disabled={!canCheck || isClaimed}
				>
					Check
				</Button>
			</SocialModalContainer>
		</Modal>
	);
};

export default SocialModal;
