import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Header, SecretCode, Timer } from './components';
import { GamesContainer } from './css';
import { Arrow } from './img';
import { canClaimReward } from 'api/v1/secret-code';
import toast from 'react-hot-toast';
import Button from 'shared/components/button';
import { GETInfoGameCard, IGetInfoGameCardResponse } from 'api/v1/card-game';

const Games = () => {
	const [statusModal, setStatusModal] = useState(false);
	const [cardGameInfo, setCardGameInfo] = useState<IGetInfoGameCardResponse | null>(null);
	const [canClick, setCanClick] = useState(true);
	const navigate = useNavigate();
	useEffect(() => {
		GETInfoGameCard().then(res => {
			setCardGameInfo(res);
		});
	}, []);

	const handleCardGame = () => {
		if (cardGameInfo && canClick) {
			setTimeout(() => {
				setCanClick(true);
			}, 3000);
			setCanClick(false);
			if (cardGameInfo.canPlay) {
				navigate('/games/card-game');
			} else {
				toast.error('Card selected! Please wait while we process the result.');
			}
		}
	};
	return (
		<>
			<GamesContainer>
				<Helmet>
					<title>PinEye | games</title>
				</Helmet>
				<div className='top_light'></div>
				<Header />

				<div className='game_list'>
					<div className='plan' onClick={handleCardGame}>
						<img src='/img/game/plan.webp' alt='' />
						<div className='content'>
							<h3 className='title'>PinEye Journey</h3>
							<p>Explore, Discover, Grow.</p>
							<Button variant='primary' className='border_btn'>
								Start Quest <Arrow />
							</Button>
						</div>
						<div className='timer_container'>{cardGameInfo && <Timer date={cardGameInfo.timeRemained * 1000} />}</div>
					</div>
					{/* <Link to={'/games/miner'}>
						<div className='caption'>
							<div className='title'>Build Miner </div>
							<div className='description'>Passive Earning Coins</div>
							<img src='/img/game/miner.webp' className='miner_pic' />
							<div className='btn_miner'>
								<p>Start Mining</p>
								<Arrow />
							</div>
						</div>
					</Link> */}

					<div
						className='secret_code'
						onClick={() => {
							canClaimReward().then(res => {
								if (!res.data.canClaim) {
									toast.error(res.data.cause);
									setStatusModal(false);
								} else {
									setStatusModal(true);
								}
							});
						}}
					>
						<img src='/img/game/secret_code_popup.webp' alt='' />
						<div className='title'>Secret Code</div>
						<div className='description'>in to Telegram and X</div>
						<div className='btn_code'>
							<p>Enter Code</p>
							<Arrow />
						</div>
					</div>

					<Link className='lottery' to={'/games/lottery'}>
						<img src='/img/game/lottery.webp' alt='' />
						<div className='title'>Big Win Lottery</div>
						<div className='description'>Take Your Chance </div>
						<div className='btn_code'>
							<p>Buy Tickets</p>
							<Arrow />
						</div>
					</Link>
				</div>
			</GamesContainer>
			{statusModal && <SecretCode status={statusModal} setStatus={setStatusModal} />}
		</>
	);
};

export default Games;
