import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { OverviewContainer } from '../css';
import { GetExchange } from 'api/v1/exchange';
import DotLoader from 'shared/components/dot-loader';
// import { useDispatch } from 'react-redux';
// import { GETInfo } from 'api/v1/miner';
// import { changeMiner } from 'store/reducers/miner';

const Overview = () => {
	const profile = useAppSelector(state => state.profile);
	const [exchangeName, setExchangeName] = useState<string | null>(null);
	// const dispatch = useDispatch();

	useEffect(() => {
		GetExchange().then(res => {
			setExchangeName(res);
		});
		// GETInfo().then(res => {
		// 	dispatch(changeMiner(res));
		// });
	}, []);

	const getExchangeImage = (exchangeName: string) => {
		switch (exchangeName.toLowerCase()) {
			case 'bybit':
				return <img src='/img/pineye/bybit.webp' className='pic ' alt=' ' />;
			case 'binance':
				return <img src='/img/pineye/binance.webp' className='pic ' alt=' ' />;
			case 'kucoin':
				return <img src='/img/pineye/kucoin.webp' className='pic ' alt=' ' />;
			case 'okx':
				return <img src='/img/pineye/okx.webp' className='pic ' alt=' ' />;
			default:
				return null;
		}
	};

	/*const openModal = () => {
		if (profit < 700) {
			setMinerModalStatus(true);
		} else {
			navigate('/games/miner');
		}
	};*/

	return (
		<OverviewContainer>
			<div className='background'>
				<img src='/img/pineye/Subtract.webp' alt='' />
			</div>
			<Link to={'/leagues'} className='card_item_container  '>
				<div className='card_item'>
					<div className='pic right'>
						<img src='/img/pineye/new_profile.webp' alt='pin' width={43} height={43} />
					</div>
					<div className='text_container'>
						<div className='title'>PinEye</div>
						<div className='value'>Level {profile.level}</div>
					</div>
				</div>
			</Link>
			<Link to='/exchange' className='card_item_container left '>
				<div className='card_item'>
					<div className='pic left'>
						{exchangeName === null || exchangeName === '' ? (
							<img src='/img/icon/exchange.webp' alt='pin' width={38} height={38} />
						) : (
							getExchangeImage(exchangeName)
						)}
					</div>
					<div className='text_container'>
						<div className='title'>Exchange</div>
						<div className='value'>{exchangeName === null ? <DotLoader /> : exchangeName === '' ? 'Choose' : exchangeName}</div>
					</div>
				</div>
			</Link>
		</OverviewContainer>
	);
};
export default Overview;
