import { useEffect, useState } from 'react';
import { GETLevels } from 'api/v2/levels';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { LightingBold } from 'shared/components/icons';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { changeLevels } from 'store/reducers/levels';
import { LevelProgressContainer } from '../css';
import numberUtils from 'shared/utils/number';
import MinerModal from './miner-modal';

const LevelProgress = () => {
	const profile = useAppSelector(state => state.profile);
	const levels = useAppSelector(state => state.levels);
	// const profit = useAppSelector(state => state.miner.profit);
	const [minerModalStatus, setMinerModalStatus] = useState(false);

	const dispatch = useDispatch();
	// const navigate = useNavigate();

	useEffect(() => {
		GETLevels().then(res => {
			dispatch(changeLevels(res));
		});
	}, []);
	const currentLevel = levels?.find(item => item.level === profile.level);
	// const openModal = () => {
	// 	if (profit < 700) {
	// 		setMinerModalStatus(true);
	// 	} else {
	// 		navigate('/games/miner');
	// 	}
	// };

	return (
		<LevelProgressContainer>
			<div className='flower_container'>
				<img src={`/img/pineye/flower_${currentLevel?.level || 1}.webp`} width={25} height={25} alt='' />
				<Link className='progress_container ' to={'/leagues'}>
					<div className='border'>
						{currentLevel && (
							<div
								className='filled'
								style={{
									width:
										profile.balance > currentLevel.toBalance
											? '100%'
											: `${(profile.balance * 100) / currentLevel.toBalance}%`,
								}}
							></div>
						)}
					</div>
				</Link>
				<img src={`/img/pineye/flower_${(currentLevel?.level || 0) + 1}.webp`} width={25} height={25} alt='' />
			</div>
			<div className='info'>
				<div>
					<Link to={'/boost'} className='energy'>
						<LightingBold />{' '}
						<p>
							{profile?.energy?.currentEnergy}/
							{profile?.energy?.maxEnergy && +profile.energy.maxEnergy > 2000
								? numberUtils.formatNumberWithSuffix(profile.energy.maxEnergy)
								: profile?.energy?.maxEnergy}
						</p>
					</Link>
				</div>
				<div>
					<Link className='level' to={currentLevel?.title ? '/leagues' : ''}>
						<img src='/img/pineye/cup.webp' width={25} height={25} alt='' /> {currentLevel?.title}
					</Link>
				</div>
				{/* <div onClick={openModal}>
					<div className='level'>
						<img src='/img/pineye/profit.webp' width={21} height={21} alt='' />Profit: {numberUtils.formatNumberWithSuffix(profit)}
					</div>
				</div> */}
				<div>
					<Link to={'/boost'} className='boost'>
						<img src='/img/pineye/jet.webp' width={25} height={25} alt='' />
						Boost
					</Link>
				</div>
			</div>
			{minerModalStatus && <MinerModal setStatus={() => setMinerModalStatus(false)} status={true} />}
		</LevelProgressContainer>
	);
};
export default LevelProgress;
