import { FC } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { GameCardModalContainer } from '../css';
import Button from 'shared/components/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeBalance } from 'store/reducers/profile';
import numberUtils from 'shared/utils/number';

const GameCardModal: FC<{
	reward: number;
	message: string;
	status: boolean;
	balance: number;
	isCorrect: boolean;
	setStatus: (status: boolean) => void;
}> = ({ isCorrect, balance, reward, message, status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onCloseClickHandler = () => {
		onClose();
	};

	const handleChoose = () => {
		if (reward > 0) {
			dispatch(changeBalance({ balance }));
		}

		onClose();
		navigate('/games');
	};
	return (
		<Modal
			isBottomSheet
			status={_status}
			onClose={onCloseClickHandler}
			title=''
			backgroundColor='linear-gradient(180deg, #0B3079 0%, #3681D0 100%)'
		>
			<GameCardModalContainer>
				<img src={'img/pineye/map.webp'} alt='' className='header_img' />
				{isCorrect && (
					<h1>
						<img src='img/dollar.webp' alt='' className='dollar' /> {numberUtils.formatPriceWithDecimals(reward)}
					</h1>
				)}
				<p dangerouslySetInnerHTML={{ __html: message }}></p>
				<Button variant='primary' onClick={handleChoose}>
					{isCorrect ? 'Move to Next Step' : 'Start Over'}
				</Button>
			</GameCardModalContainer>
		</Modal>
	);
};

export default GameCardModal;
