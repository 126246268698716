import { ISocialResponse } from 'api/v1/social';
import { FC, useState } from 'react';
import { ArrowRightOutline, TickBold } from 'shared/components/icons';
import Loader from 'shared/components/loader';
import numberUtils from 'shared/utils/number';
import { SpecialContainer } from '../css';
import SocialModal from './social-modal';
import DailyModal from './daily-modal';
import { IDailyRewardResponse } from 'api/v1/daily-reward';
import Icon from 'shared/components/icon';

interface ISocialProps {
	socialData: ISocialResponse[] | null;
	dailyData: IDailyRewardResponse | null;
	setSocialData: React.Dispatch<React.SetStateAction<ISocialResponse[] | null>>;
	setDailyData: React.Dispatch<React.SetStateAction<IDailyRewardResponse | null>>;
}

const Special: FC<ISocialProps> = ({ socialData, dailyData }) => {
	const [socialModalStatus, setSocialModalStatus] = useState<{
		status: boolean;
		id: number;
		reward: number;
		title: string;
		link: string;
		isClaimed: boolean;
		modal: ISocialResponse['modal'];
	}>({
		id: 0,
		link: '',
		reward: 0,
		title: '',
		status: false,
		isClaimed: false,
		modal: {
			buttonTitle: '',
			modalIconUrl: '',
			modalText: '',
		},
	});
	const [dailyModalStatus, setDailyModalStatus] = useState<{
		status: boolean;
	}>({
		status: false,
	});
	const partners = socialData?.filter(item => item.category === 'Special' && item.subCategory === 'Partners' && !item.isClaimed);
	const pineyTasks = socialData?.filter(item => item.category === 'Special' && item.subCategory === 'PinEye Tasks' && !item.isClaimed);

	return (
		<SpecialContainer>
			{socialData && dailyData ? (
				<>
					{pineyTasks && (pineyTasks.length > 0 || dailyData.canClaim) && (
						<div className='border'>
							<div className='other_tasks'>
								<div className='special_title'> PinEye Tasks </div>

								<div
									className='special_card'
									onClick={() => {
										setDailyModalStatus({ status: true });
									}}
								>
									<div className='img_container'>
										<Icon name={'calendar'} width={44} height={44} />
									</div>
									<div className='title'>Daily reward</div>
									<div className='value'>
										<img src='/img/dollar.webp' alt='' />
										1,000,000
									</div>
									{dailyData.canClaim ? <ArrowRightOutline /> : <TickBold className='green' />}
								</div>

								{pineyTasks?.map((item, index) => (
									<div
										className='special_card'
										key={`social_item_${index}`}
										onClick={() => {
											setSocialModalStatus({
												id: item.id,
												link: item.link,
												reward: item.score,
												title: item.title,
												status: true,
												isClaimed: item.isClaimed,
												modal: item.modal,
											});
										}}
									>
										<div className='img_container'>
											<img src={item.iconUrl} width={44} height={44} />
										</div>
										<div className='title'>{item.title}</div>
										<div className='value'>
											<img src='/img/dollar.webp' alt='' />
											{numberUtils.formatPriceWithDecimals(item.isClaimed ? item.claimedScore : item.score)}
										</div>
										{!item.isClaimed ? <ArrowRightOutline /> : <TickBold className='green' />}
									</div>
								))}
							</div>
						</div>
					)}
					{partners && partners?.length > 0 && (
						<div className='border'>
							<div className='other_tasks'>
								<div className='special_title'> Partners </div>
								{partners.map((item, index) => (
									<div
										className='special_card'
										key={`social_item_${index}`}
										onClick={() => {
											setSocialModalStatus({
												id: item.id,
												link: item.link,
												reward: item.score,
												title: item.title,
												status: true,
												isClaimed: item.isClaimed,
												modal: item.modal,
											});
										}}
									>
										<div className='img_container'>
											<img src={item.iconUrl} width={44} height={44} />
										</div>
										<div className='title'>{item.title}</div>
										<div className='value'>
											<img src='/img/dollar.webp' alt='' />
											{numberUtils.formatPriceWithDecimals(item.isClaimed ? item.claimedScore : item.score)}
										</div>
										{!item.isClaimed ? <ArrowRightOutline /> : <TickBold className='green' />}
									</div>
								))}
							</div>
						</div>
					)}
					<div className='border'>
						<div className='other_tasks'>
							<div className='special_title'> Compeleted </div>
							{socialData
								.filter(item => item.isClaimed && item.category === 'Special')
								.map((item, index) => (
									<div
										className='special_card'
										key={`social_item_${index}`}
										onClick={() => {
											setSocialModalStatus({
												id: item.id,
												link: item.link,
												reward: item.score,
												title: item.title,
												status: true,
												isClaimed: item.isClaimed,
												modal: item.modal,
											});
										}}
									>
										<div className='img_container'>
											<img src={item.iconUrl} width={44} height={44} />
										</div>
										<div className='title'>{item.title}</div>
										<div className='value'>
											<img src='/img/dollar.webp' alt='' />
											{numberUtils.formatPriceWithDecimals(item.isClaimed ? item.claimedScore : item.score)}
										</div>
										{!item.isClaimed ? <ArrowRightOutline /> : <TickBold className='green' />}
									</div>
								))}
						</div>
					</div>
				</>
			) : (
				<Loader />
			)}

			{socialModalStatus.status && (
				<SocialModal
					id={socialModalStatus.id}
					link={socialModalStatus.link}
					reward={socialModalStatus.reward}
					status={socialModalStatus.status}
					isClaimed={socialModalStatus.isClaimed}
					setStatus={status => {
						setSocialModalStatus({ ...socialModalStatus, status: status });
					}}
					title={socialModalStatus.title}
					modal={socialModalStatus.modal}
				/>
			)}
			{dailyModalStatus.status && dailyData && (
				<DailyModal
					status={dailyModalStatus.status}
					setStatus={status => {
						setDailyModalStatus({ ...dailyModalStatus, status: status });
					}}
					data={dailyData}
					title={'Daily Reward'}
				/>
			)}
		</SpecialContainer>
	);
};

export default Special;
