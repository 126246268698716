import requestHandler from 'shared/utils/request-handler';
import { IGetInfoGameCardResponse,IGetCardsResponse,IGerLastResultResponse } from './card-game.dto';

const BASE_URL = '/api/v1/Game';

export const GETInfoGameCard = async () => {
	return requestHandler.call<IGetInfoGameCardResponse>({ url: `${BASE_URL}/GetInfo`, method: 'get' }).then(res => res.data);
};

export const GETLastResult = async () => {
	return requestHandler.call<IGerLastResultResponse>({ url: `${BASE_URL}/GetLastResult`, method: 'get' }).then(res => res.data);
};

export const GETCards = async () => {
	return requestHandler.call<IGetCardsResponse>({ url: `${BASE_URL}/GetCards`, method: 'get' }).then(res => res.data);
};

export const POSTSendAnswer = async (setId: number, answerNo: number) => {
	return requestHandler
		.call<string>({ url: `${BASE_URL}/SendAnswer?setId=${setId}&answerNo=${answerNo}`, method: 'post' })
		.then(res => res.data);
};
