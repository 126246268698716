import { Dispatch, FC, SetStateAction } from 'react';
import { TabContainer } from '../css';

const Tab: FC<{
	remainAcademy: number;
	remaimainSocial: number;
	activeTab: 'SPECIAL' | 'REFERRAL' | 'ACADEMY';
	setActiveTab: Dispatch<SetStateAction<'SPECIAL' | 'REFERRAL' | 'ACADEMY'>>;
}> = ({ activeTab, setActiveTab, remaimainSocial, remainAcademy }) => {
	return (
		<TabContainer>
			<div className='border'>
				<div
					className={`tab_item ${activeTab === 'SPECIAL' ? 'active' : ''}`}
					onClick={() => {
						if (activeTab !== 'SPECIAL') {
							setActiveTab('SPECIAL');
						}
					}}
				>
					Special
					{remaimainSocial > 0 && <div className='remain_social_badge'>{remaimainSocial}</div>}
				</div>
				<div className='divider' />
				<div
					className={`tab_item ${activeTab === 'ACADEMY' ? 'active' : ''}`}
					onClick={() => {
						if (activeTab !== 'ACADEMY') {
							setActiveTab('ACADEMY');
						}
					}}
				>
					Academy
					{remainAcademy > 0 && <div className='remain_social_badge'>{remainAcademy}</div>}
				</div>
				<div className='divider' />
				<div
					className={`tab_item ${activeTab === 'REFERRAL' ? 'active' : ''}`}
					onClick={() => {
						if (activeTab !== 'REFERRAL') {
							setActiveTab('REFERRAL');
						}
					}}
				>
					Friends
				</div>
			</div>
		</TabContainer>
	);
};

export default Tab;
