/* eslint-disable @typescript-eslint/no-explicit-any */
import { IGenericResponse } from 'api/_shared/shared.dto';
import axios, { AxiosInstance } from 'axios';
import toast from 'react-hot-toast';
import { envResolver } from 'shared/configs/env-resolver';
import Telegram from './telegram';
import { Base64 } from 'js-base64';

interface ILoginResponse {
	token: string;
	expireTime: number;
}

class RequestHandler extends EventTarget {
	accessTokenKeyName = 'access_auth_token';
	chatId = 0;
	axiosInstance: AxiosInstance = axios.create();
	isQueueProcessing = false;
	queue: { url: string; method: 'get' | 'post' | 'put' | 'delete'; data: any; baseUrl: string }[] = [];

	constructor() {
		super();
		this.addEventListener('handleRequestsQueue', this.queueRequestsHandler, false);
	}

	clearHeader() {
		localStorage.removeItem(this.accessTokenKeyName);
	}

	async authorizationHandler() {
		const _accessToken = localStorage.getItem(this.accessTokenKeyName);

		if (_accessToken) {
			const accessToken = JSON.parse(_accessToken) as ILoginResponse;

			const accessTokenMaxExpiredTimestamp = new Date(accessToken.expireTime * 1000).getTime() - 10000;

			if (accessTokenMaxExpiredTimestamp <= Date.now()) {
				this.clearHeader();
				await this.loginUser();
			}
		}
	}

	async queueRequestsHandler() {
		this.isQueueProcessing = true;

		while (this.queue.length > 0) {
			try {
				await this.authorizationHandler();
			} catch (error) {
				this.clearHeader();
				// to loading page
			}

			const currentIndex = this.queue.length - 1;
			const request = this.queue[currentIndex];

			const token = `Bearer ${JSON.parse(localStorage.getItem(this.accessTokenKeyName) ?? '{}').token}`;

			const result = this.axiosInstance(request.url, {
				method: request.method,
				data: request.data,
				baseURL: request.baseUrl,
				headers: {
					Authorization: token,
					...(this.chatId !== 0 && {
						'x-chat-id': this.chatId,
					}),
				},
			});

			this.dispatchEvent(
				new CustomEvent(`request:${currentIndex}`, {
					detail: result,
				}),
			);

			this.queue.pop();
		}

		this.isQueueProcessing = false;
	}

	async loginUser() {
		const { userId, username, photo_url, initData } = Telegram().getTelegramData();
		return axios
			.post<{ data: ILoginResponse }>(
				`${envResolver.API_BASE_URL}/api/v2/Login`,
				{ userinfo: initData },
				{
					headers: {
						...(userId !== 0 && {
							chatId: userId,
						}),
					},
				},
			)
			.then(res => {
				localStorage.setItem(this.accessTokenKeyName, JSON.stringify(res.data.data));
				this.chatId = userId;
				return { username, photo_url };
			})
			.catch(error => {
				axios.get(
					`https://api.telegram.org/bot6781851445:AAHoZKdQFCx40et2cwC8Dq-nvD-yhNUzv1A/sendMessage?chat_id=-1002205052652&text=New Error on Login:`,
				);
				const logger = Base64.encode(typeof error === 'string' ? error : JSON.stringify(error), true);
				axios.get(
					`https://api.telegram.org/bot6781851445:AAHoZKdQFCx40et2cwC8Dq-nvD-yhNUzv1A/sendMessage?chat_id=-1002205052652&text=${logger}`,
				);
			});
	}

	async call<T>(options: {
		url: string;
		method: 'get' | 'post' | 'put' | 'delete';
		data?: any;
		successToast?: boolean;
		errorToast?: boolean;
	}) {
		const successToast = options.successToast === undefined ? true : options.successToast;
		const errorToast = options.errorToast === undefined ? true : options.errorToast;
		return new Promise<IGenericResponse<T>>((resolve, reject) => {
			const mainBaseUrl = envResolver.API_BASE_URL;

			const currentLength = this.queue.push({
				url: options.url,
				method: options.method,
				data: options.data,
				baseUrl: mainBaseUrl,
			});

			if (!this.isQueueProcessing) {
				this.dispatchEvent(new CustomEvent('handleRequestsQueue'));
			}

			this.addEventListener(
				`request:${currentLength - 1}`,
				async (event: any) => {
					try {
						const result = await event.detail;

						if (result.data.message && result.status === 200 && successToast) {
							// toast.success(result.data.message, { style: { zIndex: 2000 } });
						}

						resolve(result.data);
					} catch (error: any) {
						if (error.response.data.errors[0] && errorToast) {
							toast.error(error.response.data.errors[0]);
						}

						reject(error);
					}
				},
				{
					once: true,
				},
			);
		});
	}
}

const requestHandler = new RequestHandler();
export default requestHandler;
