import { useRef, useState, useEffect } from 'react';
import numberUtils from 'shared/utils/number';
import { CardGameContainer } from './css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import { EffectCards, Navigation } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper/types';
import { ArrowRightOutline } from 'shared/components/icons';
import Button from 'shared/components/button';
import AnswerModal from './components/answer-modal';
import { GETCards, IGetCardsResponse } from 'api/v1/card-game';
import Loader from 'shared/components/loader';
import { Helmet } from 'react-helmet';

const CardGame = () => {
	const swiperRef = useRef<SwiperType | null>(null);
	const [answerModalStatus, setAnswerModalStatus] = useState(false);
	const [data, setData] = useState<IGetCardsResponse | null>(null);
	const [cards, setCards] = useState<IGetCardsResponse['set']['cards']['choice1' | 'choice2' | 'choice3'][]>([]);
	const [activeCard, setActiveCard] = useState<IGetCardsResponse['set']['cards']['choice1' | 'choice2' | 'choice3'] | null>(null);

	const onNextClickHandler = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	};

	const onPrevClickHandler = () => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	};

	useEffect(() => {
		GETCards()
			.then(res => {
				setData(res);
				setCards([res.set.cards.choice1, res.set.cards.choice2, res.set.cards.choice3]);
				setActiveCard(res.set.cards.choice1);
			})
			.catch(err => {
				console.log(err);
			});
	}, []);

	const handleSlideChange = (swiper: SwiperType) => {
		const currentSlide = cards[swiper.activeIndex];
		setActiveCard(currentSlide);
	};

	const onCloseModal = (state: boolean) => {
		setAnswerModalStatus(state);
	};

	return (
		<CardGameContainer>
			<Helmet>
				<title>PinEye | Card Game</title>
			</Helmet>
			{!data ? (
				<Loader />
			) : (
				<>
					<img src={data.level.backImageUrl} alt='' className='background' />
					<h1>{data.level.title}</h1>
					<p>{data.level.caption}</p>
					<div className='box'>
						<img src='/img/game/q.webp' alt='' className='q' />
						<h3>{data.set.question}</h3>
						<div className='line' />
						<h4>
							Reward: <img src='/img/dollar.webp' alt='' /> {numberUtils.formatPriceWithDecimals(data?.set.reward)}
						</h4>
					</div>
					<div className='swiper_container'>
						<Swiper
							onSlideChange={handleSlideChange}
							navigation={{ nextEl: 'next', prevEl: 'prev' }}
							onSwiper={swiper => {
								swiperRef.current = swiper;
							}}
							effect={'cards'}
							grabCursor={true}
							modules={[EffectCards, Navigation]}
							className='mySwiper'
						>
							{cards.map(card => (
								<SwiperSlide key={card.title}>
									<img src={card.imageUrl} alt={card.title} />
									{card.title}
								</SwiperSlide>
							))}
						</Swiper>
						<div className='prev' onClick={onPrevClickHandler}>
							<ArrowRightOutline />
						</div>
						<div className='next' onClick={onNextClickHandler}>
							<ArrowRightOutline />
						</div>
					</div>

					<div className='button_container'>
						<Button
							variant='primary'
							isLoading={answerModalStatus}
							className='lets_go'
							onClick={() => setAnswerModalStatus(true)}
						>
							Let's Go
						</Button>
						<Button variant='primary' className='history' disabled={true}>
							<p>Card History</p> <span>(Coming Soon)</span>
						</Button>
					</div>
				</>
			)}
			{answerModalStatus && data && activeCard && (
				<AnswerModal
					modalCaption={data.level.modalCaption}
					activeCard={activeCard}
					setId={data.set.id}
					status={answerModalStatus}
					setStatus={onCloseModal}
				/>
			)}
		</CardGameContainer>
	);
};

export default CardGame;
